import styled from 'styled-components'
import { Col, Row } from 'antd'
import Hook from '../shared/Hook'
import Details from '../shared/Details';

export const CenteredCol = styled(Col)`
  margin: 0 auto;
  text-align: center;
`

export default function Login(): JSX.Element {
  return (
    <Row>
      <Hook />
      <Details />
    </Row >
  );
}

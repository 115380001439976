import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { logoutUser, User } from '../home/homeSlice'
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

interface Props {
  user: User
}

const Right = styled.div`
  float: right;
`

const Welcome = styled.span`
  color: #333;
`

const WelcomeText = styled.span`
  @media (max-width: 576px) {
    display: none;
  }
`

const Picture = styled.img`
  width: 40px;
  border-radius: 100px;
  margin-bottom: 10px;
  margin-right: 5px;
  vertical-align: -webkit-baseline-middle;
`

export default function Avatar({ user: { name, picture } }: Props) {
  const dispatch = useDispatch()

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          dispatch(logoutUser())
        }} key="0"
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Right>
      <Dropdown overlay={menu} trigger={['click']}>
        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          <Picture src={picture} /><Welcome><WelcomeText>Welcome, {name}</WelcomeText>&nbsp;<DownOutlined /></Welcome>
        </a>
      </Dropdown>
    </Right>
  )
}

import styled from 'styled-components';
import { CenteredCol } from '../login/Login';
import { HookText, SubHookText } from './Hook';

import courses from '../lindy.png'
import assessment from '../assessment.png'
import assignments from '../assignments.png'
import content from '../content.png'
import questions from '../questions.png'
import video from '../video.png'

const DetailsWrapper = styled.div`
  height: 100%;
  padding-bottom: 50px;
`

const ColorWrapper = styled.div`
  height: inherit;

  &:before {
    content: "";
    z-index: -1;
    position: absolute;
    background-color: #F6E8EA;
    width: 100vw;
    height: inherit;
    left: 50%;
    transform: translateX(-50%);
  }
`

const DetailsText = styled(HookText)`
  font-size: 45px;
  text-align: center;
  padding-top: 50px;
`

const SubDetailsText = styled(SubHookText)`
  font-size: 18px;
  text-align: left;
`

const EmphasizedText = styled.span`
  font-size: 20px;
  color: #570A57;
  font-weight: 800;
`

const Screenshot = styled.img`
  margin-bottom: 50px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0px 0px 8px #ccc;
  border-radius: 15px;
`

export default function Details() {
  return (
    <CenteredCol>
      <DetailsWrapper>
        <ColorWrapper>
          <DetailsText>How Lindy Works!</DetailsText>
          <SubDetailsText>
            <EmphasizedText>Step 1:</EmphasizedText> Use the <EmphasizedText>/courses</EmphasizedText> command to view a list of available courses in your workspace or create a new one.
          </SubDetailsText>
          {/* <Screenshot src={courses} alt="screenshot" /> */}
          <SubDetailsText>
            <EmphasizedText>Step 2:</EmphasizedText> Assign courses to users in your workspace.
          </SubDetailsText>
          {/* <Screenshot src={courses} alt="screenshot" /> */}
          <SubDetailsText>
            <EmphasizedText>Step 3:</EmphasizedText> Users use the <EmphasizedText>/assignments</EmphasizedText> command to view courses assigned to them.
          </SubDetailsText>
          {/* <Screenshot src={assignments} alt="screenshot" /> */}
          <SubDetailsText>
            <EmphasizedText>Step 4:</EmphasizedText> Lindy delivers the learning content to the user via direct message using text and video formats.
          </SubDetailsText>
          {/* <Screenshot src={content} alt="screenshot" />
          <Screenshot src={video} alt="screenshot" /> */}
          <SubDetailsText>
            <EmphasizedText>Step 5:</EmphasizedText> Once the learning content has been delievered, Lindy assesses the user on what they've learned.
          </SubDetailsText>
          {/* <Screenshot src={assessment} alt="screenshot" /> */}
          <SubDetailsText>
            <EmphasizedText>Step 6:</EmphasizedText> Finally, after the course is completed by a user, the user's name will show up in the completed view.
          </SubDetailsText>
          {/* <Screenshot src={courses} alt="screenshot" /> */}
        </ColorWrapper>
      </DetailsWrapper>
    </CenteredCol>
  )
}
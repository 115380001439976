import { CenteredCol } from '../login/Login'
import styled from 'styled-components';
import screenshot from '../lindy.png'
import LoginButton from './LoginButton';
import AddButton from './AddButton';

interface HookProps {
  isAuthed?: boolean
}

export const HookText = styled.div`
  margin: 0 auto;
  font-family: Lato, sans-serif;
  margin-bottom: 20px;
  font-size: 50px;
  font-weight: 900;
  color: #570A57;
  max-width: 600px;
`

export const SubHookText = styled.div`
  margin: 0 auto;
  font-family: Lato, sans-serif;
  margin-bottom: 30px;
  font-size: 20px;
  color: #333333;
  max-width: 600px;
`

const Screenshot = styled.img`
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0px 0px 8px #ccc;
  border-radius: 15px;
`

const HookBottom = styled.div`
  height: 50px;
  width: 100%;
`

export default function Hook({ isAuthed = false }: HookProps) {
  return (
    <>
      <CenteredCol xs={24}>
        <HookText>
          Upskill your employees with Slack!
        </HookText>
        <SubHookText>Create and deliver just-in-time, microlearning modules to upskill and develop your employees directly through Slack!</SubHookText>
      </CenteredCol>
      <CenteredCol md={24}>
        {isAuthed ? <AddButton /> : <LoginButton />}
      </CenteredCol>
      <CenteredCol xs={24}>
        <Screenshot src={screenshot} alt="screenshot" />
      </CenteredCol>
      <HookBottom />
    </>
  )
}

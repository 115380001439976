import HashLoader from "react-spinners/HashLoader";
import Centered from "./Centered";

export default function Loading() {
  return (
    <Centered>
      <HashLoader color="#ec70be" size={100} />
    </Centered>
  )
}

import styled from "styled-components";
import { Pricing } from "../shared/Pricing";
import Subscribed from "../shared/Subscribed";
import { Row } from 'antd';
import { User } from "./homeSlice";
import Hook from "../shared/Hook";
import Details from "../shared/Details";

interface HomeProps {
  user: User
}

export const SectionHeader = styled.h1`
  text-align: center;
  bottom: 20px;
  font-weight: 900;
  font-size: 50px;
  color: #570A57;
`

export const Home = ({ user }: HomeProps) => {
  return (
    <Row>
      {(!user.subscribed && !user.installed) &&
        <>
          <Hook isAuthed />
          <Details />
        </>
      }
      {(user.subscribed || user.installed) && <Subscribed user={user} />}
      {!user.subscribed && <Pricing user={user} />}
    </Row>
  )
}

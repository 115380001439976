import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createGlobalStyle } from 'styled-components'
import { Provider } from 'react-redux';
import store from './store';

import 'antd/dist/antd.css';
import './font.css';

const GlobalStyle = createGlobalStyle`
  body {
    background: #FFFFFF;
    font-family: 'Lato';

    a {
      color: #E36397;

      &:hover {
        color: #E36397;
        filter: brightness(85%);
      }
    }
  }

  body:before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 90px;
    left: 0;
    width: 170px;
    height: 100vw;
    background: #F6E8EA;
    -webkit-transform: rotate(-91deg);
    -ms-transform: rotate(-91deg);
    transform: rotate(-91deg);
    transform-origin: 0% 0%;
  }
`

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

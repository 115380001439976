import { BrowserRouter, Routes, Route, Navigate, useNavigate, Link } from 'react-router-dom';
import Checkout from './checkout/Checkout';
import { Home } from "./home/Home";
import Login from "./login/Login";
import Loading from './shared/Loading';
import useUser from './user/userHook';
import styled from 'styled-components';
import logo from './logo.png'
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import { Col, Layout, Row } from 'antd';
import Avatar from './shared/Avatar';
import Privacy from './privacy/Privacy';
import Terms from './terms/Terms';
import Cookie from './cookie/Cookie';
import Acceptable from './acceptable/Acceptable';
import LoginButton from './shared/LoginButton';
import Support from './support/Support';

const Background = styled(Layout)`
  background: transparent;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 30px;
`

const Navbar = styled(Header)`
  background-color: transparent;
  height: 70px;
  padding: 0px;

  &:before {
    z-index: -2;
    content: "";
    position: absolute;
    top: 100px;
    left: 0;
    width: 170px;
    height: 100vw;
    background: #ED70BE;
    -webkit-transform: rotate(-91deg);
    -ms-transform: rotate(-91deg);
    transform: rotate(-91deg);
    transform-origin: 0% 0%;
  }
`

const NavbarLoginButton = styled.span`
  a {
    margin-top: 10px;
    float: right;
    width: 185px;
    font-size: 16px;
    height: 40px;
  }

  @media (max-width: 576px) {
    display: none;
  }
`

const NavbarAvatar = styled(Avatar)`
  float: right;
`

const ContentTableWrapper = styled.div`
  display: table;
`

const ContentRowWrapper = styled.div`
  display: table-row;
`

const AppContent = styled(Content)`
  height: calc(100vh - 200px);
  padding: 3em 0;
  padding-bottom: 100px;
  display: table-cell;
  vertical-align: middle;
`

const LogoLink = styled(Link)`
  color: #570A57;
  font-size: 45px;
  font-weight: 500;
  font-family: 'Nunito';

  &:hover {
    color: #570A57;
  }
`

const Logo = styled.img`
  width: 60px;
  margin-right: 5px;
`

const BottomBar = styled(Footer)`
  background-color: transparent;
  padding: 24px 0px;
`

const RightCol = styled(Col)`
 text-align: right;
`

export default function App(): JSX.Element {
  const [user, loading, error] = useUser()

  if ((!user && !error) || loading) {
    return <Loading />
  }

  const isAuthed = !!user

  return (
    <Background>
      <BrowserRouter>
        <Navbar>
          <LogoLink to='home'>
            <Logo src={logo} alt="logo" />lindy
          </LogoLink>
          {isAuthed ? <NavbarAvatar user={user} /> : <NavbarLoginButton><LoginButton /></NavbarLoginButton>}
        </Navbar>
        <ContentTableWrapper>
          <ContentRowWrapper>
            <AppContent>
              <Routes>
                <Route path="*" element={!isAuthed || error ? <Navigate to='/login' /> : <Navigate to='/home' />} />
                <Route path="login" element={!isAuthed || error ? <Login /> : <Navigate to='/home' />} />
                <Route path="home" element={isAuthed ? <Home user={user} /> : <Navigate to='/login' />} />
                <Route path="checkout" element={isAuthed ? <Checkout /> : <Navigate to='/login' />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms" element={<Terms />} />
                <Route path="support" element={<Support />} />
                <Route path="cookies" element={<Cookie />} />
                <Route path="acceptable-use" element={<Acceptable />} />
              </Routes>
            </AppContent>
          </ContentRowWrapper>
        </ContentTableWrapper>
        <BottomBar>
          <Row>
            <Col xs={12}>
              Urple, LLC ©{new Date().getFullYear()}<br />
              <Link to='support'>Lindy Support</Link><br /><br />
            </Col>
            <RightCol xs={12}>
              <Link to='terms'>Terms of Service</Link><br />
              <Link to='privacy'>Privacy Policy</Link><br />
              <Link to='cookies'>Cookie Policy</Link><br />
              <Link to='acceptable-use'>Acceptable Use Policy</Link>
            </RightCol>
          </Row>
        </BottomBar>
      </BrowserRouter>
    </Background >
  );
}

import { configureStore } from '@reduxjs/toolkit'
import homeReducer from './home/homeSlice'
import { HomeState } from './home/homeSlice';

export interface AppState {
  home: HomeState
}

const reducer = {
  home: homeReducer,
}

const store = configureStore({
  reducer,
})

export default store
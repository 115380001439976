import { ReactElement } from 'react'
import { SectionHeader } from '../home/Home'
import styled from 'styled-components';
import { Col } from 'antd';
import { User } from '../home/homeSlice';

interface SubscribedProps {
  user: User
}

const SubscribedSection = styled.div`
  text-align: center;
`

const Text = styled.p`
  font-size: 20px;
`

const SubText = styled.p`
  font-size: 16px;
`

export default function Subscribed({ user }: SubscribedProps): ReactElement {
  const isSubscribed = user.subscribed

  return (
    <Col xs={24}>
      <SubscribedSection>
        <SectionHeader>Congrats!</SectionHeader>
        <Text>Your workspace has successfully {isSubscribed ? 'subscribed to' : 'installed'} Lindy.<a href='slack://open'> Open Slack</a> to get started!</Text>
        {isSubscribed ? (
          <SubText>Cancel anytime by emailing<a href='mailto:hello@lindy.chat'> hello@lindy.chat</a>.</SubText>
        ) : (
          <SubText>Upgrade to premium anytime by selecting an option below.</SubText>
        )}
      </SubscribedSection>
    </Col>
  )
}

import { Col, Card, Button, Row, Slider } from 'antd'
import { useState } from 'react';
import styled from 'styled-components';
import { calculatePrice } from '../checkout/Checkout';
import { SectionHeader } from '../home/Home';
import { User } from '../home/homeSlice';
import AddButton, { installUrl } from './AddButton';

interface PricingProps {
  user: User
}

const SectionSubheader = styled.h1`
  font-size: 35px;
  margin-top: -20px;
  padding-bottom: 25px;
  text-align: center;
  font-weight: 900;
  color: #570A57;
`

const TeamSizeSlider = styled(Slider)`
  margin-bottom: 20px;

  .ant-slider-track {
    background-color: #570A57;
  }
`

const TeamSizeText = styled.div`
  text-align: center;
  font-size: 20px;
  color: #333333;
  margin-bottom: 20px;
`

const TeamSizeTextNumeric = styled.span`
  font-weight: 900;
  font-size: 30px;
  color: #570A57;
`

const PricingSection = styled.div`
  margin-top: 50px;
`

const PricingCard = styled(Card)`
  text-align: center;

  &.ant-card {
    border-radius: 20px;
    box-shadow: #aaaaaa 0px 0px 5px;
    margin: 0 auto;
    min-height: 350px;
  }

  .ant-card-head {
    color: #ffffff;
    background-color: #570A57;
    font-size: 18px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-weight: 900;
  }
`

const Price = styled.div`
  font-size: 40px;
  color: #570A57;
  margin-bottom: 24px;
  font-weight: 700;
`

const Per = styled.span`
  font-size: 18px;
`

const FeatureList = styled.div`
  text-align: left;
  margin-bottom: 20px;
`

const AddButtonWrapper = styled.div`
  a {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
    left: 24px;
  }
`

const SignUpButton = styled(Button)`
  height: 40px;
  text-shadow: none;
  background-color: #570A57;
  border-color: #570A57;
  border-radius: 5px;
  font-weight: 700;
  position: absolute;
  bottom: 24px;
  width: calc(100% - 48px);
  left: 24px;

  &:hover {
    background-color: #570A57;
    border-color: #570A57;
    filter: brightness(85%);
  }
`

export const Pricing = ({ user }: PricingProps) => {
  const [teamSize, setTeamSize] = useState(24)

  return (
    <Col md={24}>
      <PricingSection>
        <Col md={24}>
          {!user.installed && <SectionHeader>Pricing</SectionHeader>}
          <SectionSubheader>Try FREE for 30 days!</SectionSubheader>
          <TeamSizeSlider defaultValue={teamSize} min={1} max={1000} onChange={setTeamSize} tooltipVisible />
          <TeamSizeText>Prices based on workspace size of <TeamSizeTextNumeric>{teamSize}</TeamSizeTextNumeric> users</TeamSizeText>
        </Col >
        <Row gutter={[16, 16]}>
          {!user.installed && (<Col xs={24} md={8}>
            <PricingCard title="Free" bordered={false}>
              <Price>$0 <Per>/ mo</Per></Price>
              <FeatureList>
                ✅ One course per user<br />
                ✅ Unlimited assignments<br />
              </FeatureList>
              <AddButtonWrapper><AddButton /></AddButtonWrapper>
            </PricingCard>
          </Col>)}
          <Col xs={24} md={user.installed ? 12 : 8}>
            <PricingCard title="Annually (best value)" bordered={false}>
              <Price>${calculatePrice(teamSize, 'year')} <Per>/ mo</Per></Price>
              <FeatureList>
                ✅ Lower price than monthly<br />
                ✅ Unlimited courses<br />
                ✅ Unlimited assignments<br />
                ✅ Gamified rewards with leaderboard<br />
              </FeatureList>
              <SignUpButton
                onClick={() => {
                  window.location.replace(`https://slack.com/oauth/v2/authorize?redirect_uri=${process.env.REACT_APP_SERVER_URL}${installUrl}&state=year&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&interval=year`)
                }}
                size="lg"
                type="primary"
                block>
                Start your free trial!
              </SignUpButton>
            </PricingCard>
          </Col>
          <Col xs={24} md={user.installed ? 12 : 8}>
            <PricingCard title="Monthly" bordered={false}>
              <Price>${calculatePrice(teamSize, 'month')} <Per>/ mo</Per></Price>
              <FeatureList>
                ✅ Unlimited courses<br />
                ✅ Unlimited assignments<br />
                ✅ Gamified rewards with leaderboard<br />
              </FeatureList>
              <SignUpButton
                onClick={() => {
                  window.location.replace(`https://slack.com/oauth/v2/authorize?redirect_uri=${process.env.REACT_APP_SERVER_URL}${installUrl}&state=month&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&interval=month`)
                }}
                size="lg"
                type="primary"
                block>
                Start your free trial!
              </SignUpButton>
            </PricingCard>
          </Col>
        </Row>
      </PricingSection>
    </Col>
  )
}

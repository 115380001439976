import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

export interface User {
  name: string
  picture: string
  subscribed: boolean
  installed: boolean
  teamId: string
}

export interface HomeState {
  user?: User
  loading: boolean
  error?: any
}

export const fetchUser = createAsyncThunk(
  'users/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/user')

      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const logoutUser = createAsyncThunk(
  'users/logout',
  async (_, { rejectWithValue }) => {
    try {
      await axios.get('/auth/logout')
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

const homeSlice = createSlice({
  name: 'home',
  initialState: { user: undefined, loading: false } as HomeState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action: PayloadAction<User>) => {
        state.loading = true
      })
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.user = action.payload
        state.loading = false
      })
      .addCase(fetchUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(logoutUser.pending, (state, action: PayloadAction<any>) => {
        state.loading = true
      })
      .addCase(logoutUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.user = undefined
        state.loading = false
      })
      .addCase(logoutUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload
        state.loading = false
      })
  },
})

export default homeSlice.reducer
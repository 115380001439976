import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchUser } from "../home/homeSlice"
import { AppState } from "../store"

export default function useUser() {
  const dispatch = useDispatch()

  const user = useSelector((state: AppState) => state.home.user)
  const loading = useSelector((state: AppState) => state.home.loading)
  const error = useSelector((state: AppState) => state.home.error)

  useEffect(() => {
    if (!user && !loading && !error) {
      dispatch(fetchUser())
    }
  }, [fetchUser, user, loading, error])

  return [user, loading, error]
}
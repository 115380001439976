import { useState, useEffect } from "react";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import axios from "axios";
import Centered from "../shared/Centered";
import Loading from "../shared/Loading";
import { useLocation } from 'react-router-dom';
import styled from "styled-components";
import { useDispatch } from 'react-redux';
import { Row } from "antd";
import { CenteredCol } from "../login/Login";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

interface CheckoutData {
  clientSecret: string
  teamSize: number
  interval: string
}

const Free = styled.div`
  font-size: 50px;
  color: #570A57;
  font-weight: 900;
`

const Price = styled.div`
  font-size: 40px;
  color: #570A57;
  margin-bottom: 24px;
  font-weight: 900;
`

const Per = styled.span`
  font-size: 18px;
  font-weight: 500;
`

const TeamSizeText = styled.div`
  text-align: center;
  font-size: 20px;
  color: #333333;
  margin-top: -20px;
  margin-bottom: 30px;
`

const TeamSizeTextNumeric = styled.span`
  font-weight: 900;
  font-size: 30px;
  color: #570A57;
`

export const calculatePrice = (teamSize: number, interval: string) => {
  switch (interval) {
    case 'year':
      if (teamSize < 25) {
        return 49
      } else if (teamSize < 50) {
        return 99
      } else if (teamSize < 100) {
        return 149
      } else if (teamSize < 200) {
        return 199
      } else if (teamSize < 500) {
        return 299
      } else if (teamSize < 1000) {
        return 399
      } else {
        return 499
      }
    case 'month':
      if (teamSize < 25) {
        return 59
      } else if (teamSize < 50) {
        return 119
      } else if (teamSize < 100) {
        return 179
      } else if (teamSize < 200) {
        return 239
      } else if (teamSize < 500) {
        return 359
      } else if (teamSize < 1000) {
        return 479
      } else {
        return 599
      }
    default:
      if (teamSize < 25) {
        return 59
      } else if (teamSize < 50) {
        return 119
      } else if (teamSize < 100) {
        return 179
      } else if (teamSize < 200) {
        return 239
      } else if (teamSize < 500) {
        return 359
      } else if (teamSize < 1000) {
        return 479
      } else {
        return 599
      }
  }
}

export default function Checkout() {
  const [checkoutData, setCheckoutData] = useState<CheckoutData>({
    clientSecret: '',
    teamSize: 0,
    interval: ''
  });

  const location = useLocation()

  useEffect(() => {
    axios.post("/api/intent",
      {
        interval: location.search.includes('year') ? 'year' : 'month'
      }, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res) => setCheckoutData(res.data))
  }, []);

  if (!checkoutData.clientSecret) {
    return <Loading />
  }

  const appearance = {
    theme: 'stripe',
  }

  const options = {
    clientSecret: checkoutData.clientSecret,
    appearance,
  } as StripeElementsOptions

  return (
    <>
      {checkoutData.clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <Row>
            <CenteredCol md={24}>
              <Free>Try FREE for 30 days!</Free>
              <Price>${calculatePrice(checkoutData.teamSize, checkoutData.interval)}
                <Per> per month {checkoutData.interval === 'year' ? 'billed annually' : ''}</Per>
              </Price>
              <TeamSizeText>Price based on workspace size of <TeamSizeTextNumeric>{checkoutData.teamSize}</TeamSizeTextNumeric> users with {checkoutData.interval === 'year' ? 'annual' : 'monthly'} billing</TeamSizeText>
              <CheckoutForm />
            </CenteredCol>
          </Row>
        </Elements>
      )}
    </>
  );
}
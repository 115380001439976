import styled from 'styled-components';

const LoginContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

export default LoginContainer